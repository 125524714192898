import { makeStyles } from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleIcon from '@material-ui/icons/People';
import { Downloading } from '@mui/icons-material';
import useLocalStorage from '@rehooks/local-storage';
import * as React from 'react';
import { MenuItemLink } from 'react-admin';
const useStyles = makeStyles({
  avatar: {
    height: '30px',
    width: '30px',
    marginRight: '0.5vw',
  },
});

export const Menu = () => {
  const [role] = useLocalStorage('role');
  const classes = useStyles();
  return (
    <div>
      <MenuItemLink
        to="/"
        primaryText="Dashboard"
        leftIcon={<DashboardIcon className={classes.avatar} />}
      />
      {role === 'admin' ? (
        <MenuItemLink
          to="/user"
          primaryText="Users"
          leftIcon={<PeopleIcon className={classes.avatar} />}
        />
      ) : null}
      <MenuItemLink
        to="/manifest"
        primaryText="Manifest"
        leftIcon={<DescriptionIcon className={classes.avatar} />}
      />
      <MenuItemLink
        to="/simplifiedDispatch/create"
        primaryText="Simplfied Dispatch"
        leftIcon={<Downloading className={classes.avatar} />}
      />
    </div>
  );
};
