import { fetchUtils } from 'react-admin';

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const token = JSON.parse(localStorage.getItem('auth'));
  options.headers.set('Authorization', `Bearer ${token}`);

  if (options.responseType === 'blob') {
    return fetch(url, options).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.blob().then((blob) => ({ body: blob }));
    });
  }

  return fetchUtils.fetchJson(url, options);
};
