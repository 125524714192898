import fileDownload from 'js-file-download';
import simpleRestProvider from 'ra-data-simple-rest';

import { httpClient } from './httpClient/HttpClient';

const servicesHost = process.env.REACT_APP_API_URL;

const dataProvider = simpleRestProvider(servicesHost, httpClient);

const myDataProvider = {
  ...dataProvider,
  create: (resource, params) => {
    if (resource !== 'manifest' && resource !== 'simplifiedDispatch') {
      return dataProvider.create(resource, params);
    }
    if (resource === 'simplifiedDispatch') {
      const formData = new FormData();
      formData.append('file', params.data.files.rawFile);

      return httpClient(`${servicesHost}/${resource}`, {
        method: 'POST',
        body: formData,
        responseType: 'blob',
      })
        .then(({ body }) => {
          if (!(body instanceof Blob)) {
            throw new Error('La respuesta no contiene un archivo válido.');
          }
          const blob = new Blob([body], { type: 'application/zip' });
          fileDownload(blob, 'invoices.zip');
          return { data: { ...params.data, id: 'unique' } };
        })
        .catch((error) => {
          throw error;
        });
    }
    if (params.data.files.rawFile) {
      const formData = new FormData();
      formData.append('file', params.data.files.rawFile);
      if (params.data.date) {
        formData.append('date', params.data.date);
      }
      return httpClient(`${servicesHost}/${resource}`, {
        method: 'POST',
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    }
  },
  update: (resource, params) => {
    if (resource !== 'manifest') {
      return dataProvider.update(resource, params);
    }
    if (params.data.files.rawFile) {
      const formData = new FormData();
      formData.append('file', params.data.files.rawFile);

      return httpClient(`${servicesHost}/${resource}/${params.id}`, {
        method: 'PUT',
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    }
    const formData = new FormData();
    formData.append('file', params);

    return httpClient(`${servicesHost}/${resource}/${params.id}`, {
      method: 'PUT',
      body: formData,
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },
};

export default myDataProvider;

// import axios from 'axios';
// import fileDownload from 'js-file-download';
// import simpleRestProvider from 'ra-data-simple-rest';

// import { httpClient } from './httpClient/HttpClient';

// const servicesHost = process.env.REACT_APP_API_URL;

// const dataProvider = simpleRestProvider(servicesHost, httpClient);

// const myDataProvider = {
//   ...dataProvider,
//   create: (resource, params) => {
//     if (resource !== 'manifest' && resource !== 'simplifiedDispatch') {
//       return dataProvider.create(resource, params);
//     }
//     if (resource === 'simplifiedDispatch') {
//       return new Promise(() => {
//         const formData = new FormData();
//         formData.append('file', params.data.files.rawFile);
//         const config = {
//           headers: {
//             'content-type': 'multipart/form-data',
//           },
//         };
//         axios
//           .post(`${servicesHost}/${resource}`, formData, config)
//           .then((data) => {
//             fileDownload(Buffer.from(data.data), 'accounting.zip');
//             return { id: 'unique' };
//           });
//       });
//     }
//     if (params.data.files.rawFile) {
//       const formData = new FormData();
//       formData.append('file', params.data.files.rawFile);
//       if (params.data.date) {
//         formData.append('date', params.data.date);
//       }
//       return httpClient(`${servicesHost}/${resource}`, {
//         method: 'POST',
//         body: formData,
//       }).then(({ json }) => ({
//         data: { ...params.data, id: json.id },
//       }));
//     }
//   },
//   update: (resource, params) => {
//     if (resource !== 'manifest') {
//       return dataProvider.update(resource, params);
//     }
//     if (params.data.files.rawFile) {
//       const formData = new FormData();
//       formData.append('file', params.data.files.rawFile);

//       return httpClient(`${servicesHost}/${resource}/${params.id}`, {
//         method: 'PUT',
//         body: formData,
//       }).then(({ json }) => ({
//         data: { ...params.data, id: json.id },
//       }));
//     }
//     const formData = new FormData();
//     formData.append('file', params);

//     return httpClient(`${servicesHost}/${resource}/${params.id}`, {
//       method: 'PUT',
//       body: formData,
//     }).then(({ json }) => ({
//       data: { ...params.data, id: json.id },
//     }));
//   },
// };

// export default myDataProvider;
