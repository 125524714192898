import React from 'react';
import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  TextField,
} from 'react-admin';

import { PostPagination } from '../pagination/Pagination';
import { PostReferenceInput } from './UserPost';

const CommentEditButton = ({ record }) => (
  <EditButton basePath="/user" label="Edit" resource="user" record={record} />
);

const UserList = (props) => (
  <List
    actions={<PostReferenceInput />}
    pagination={<PostPagination />}
    exporter={false}
    {...props}
  >
    <Datagrid>
      <TextField source="email" label="EMAIL" />
      <FunctionField
        label="ROLE"
        render={(record) => (record.isAdmin ? 'Admin' : 'User')}
      />
      <CommentEditButton label="ACTION" />
    </Datagrid>
  </List>
);

export default UserList;
