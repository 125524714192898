import * as React from 'react';
import { Create, FileField, FileInput, SimpleForm } from 'react-admin';

const SimplifiedDispatchCreate = (props) => (
  <Create {...props} redirect={false} basePath="" resource="">
    <SimpleForm>
      <FileInput
        id="file"
        source="files"
        placeholder={<p>Drop your file here</p>}
      >
        <FileField />
      </FileInput>
    </SimpleForm>
  </Create>
);
export default SimplifiedDispatchCreate;
